





















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import axios from 'axios'
import Common from '@/mixins/Common.vue'

const UIMutation = namespace('ui').Mutation
const UIGetter = namespace('ui').Getter;
const SettingsGetter = namespace('settings').Getter
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  props: {},
  mixins: [Common],
  data: () => {
    return {
      progressive_bet: 0,
      isAccountLoaded: false
    }
  },
  methods: {
    async getRuleSet() {
      try {
        let res = await axios.get('/api/blackjack/ruleset')
        if(res.data.result) {
          return res.data.result
        } else {
          throw new Error('Invalid blackjack ruleset response')
        }
      }
      catch(e) {
        this.$router.push('maintenance')
        console.log("Error trying to get the blackjack ruleset")
        return e
      }
    },
    async getSeed() {
      try {
        let res = await axios.post('/api/blackjack/seed')
        if(res.data.server_seed_hash) {
          return res.data.server_seed_hash
        } else {
          throw new Error('Invalid blackjack seed response')
        }
      }
      catch(e) {
        console.log("Error getting blackjack seed")
        return e
      }
    },
    async getExistingGameIfAvailable() {
        let res = await axios.post('/api/blackjack/game/exist');
        return res.data;
    },
    async initGame() {
      this.isAccountLoaded = true;
      try {
        this.initializeGameScale()
        // #help rules is created in blackjack.js
        //@ts-ignore
        // eslint-disable-next-line no-undef
        await this.$loadScript("/js/legacy/blackjack.js")
        let ruleset = await this.getRuleSet()
        let existingGame = await this.getExistingGameIfAvailable();
        // this.progressive_bet = ruleset.valid_credit_sizes[ruleset.valid_credit_sizes.length - 1]
        this.progressive_bet = 0
        let starting_server_seed_hash = await this.getSeed()
        // @ts-ignore
        // eslint-disable-next-line no-undef
        init_blackjack('bj', 'bj', 'bj', starting_server_seed_hash, [], [], [], ruleset, this.progressive_bet, existingGame)
      } catch (e) {
        console.log('Error', e)
      }
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Classic Lucky 7’s Progressive Blackjack Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Blackjack`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  watch: {
    vueAppKey: function () {
      this.initGame()
    }
  },
  created () {
    // @ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  async mounted () {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init blackjack game when the token
    // is available
    this.initGame();
  }
})
@Component({
  components: {
    FastGameMenu
  }
})
export default class Blackjack extends AppProps {
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @SettingsGetter getPlatformCurrency!: String
  @AccountGetter userToken
  @AccountGetter isLoggedIn
  @UIGetter vueAppKey
}
